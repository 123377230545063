import React, { FC } from 'react';

import { TaskView, STIMULUS_CONTENT_TYPES } from 'Shared/types/shared';

import { TextContent } from './text';
import { HtmlContent } from './html';
import { ImageContent } from './image';
import { AudioContent } from './audio';
import { VideoContent } from './video';
// import { ImageWithAudioContent } from './imageWithAudio';

import styles from './index.module.scss';

export const Stimulus: FC<{ task: TaskView }> = ({ task }) => {
  return (
    <div className={styles.stimulus}>
      {task.stimulus.map((item, index) => {
        if (item.contentType === STIMULUS_CONTENT_TYPES.TEXT) {
          return <TextContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.HTML) {
          return <HtmlContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.IMAGE) {
          return <ImageContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.AUDIO) {
          return <AudioContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        if (item.contentType === STIMULUS_CONTENT_TYPES.VIDEO) {
          return <VideoContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />;
        }
        // if (item.contentType === STIMULUS_CONTENT_TYPES.HTML) {
        //   return (
        //     <ImageWithAudioContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />
        //   );
        // }
        // if (item.contentType === STIMULUS_CONTENT_TYPES.AUDIO_PLAYER) {
        //   return (
        //     <ImageWithAudioContent stimulus={item} resourceUrl={task.resourceUrl} key={index} />
        //   );
        // }
      })}
    </div>
  );
};
