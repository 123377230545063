import React, { Dispatch, FC, SetStateAction } from 'react';

import { TaskView } from 'Shared/types/shared';

interface MemoryBoardProps {
  task: TaskView;
  isAnswered: boolean;
  setIsAnswered: Dispatch<SetStateAction<boolean>>;
}

export const MemoryBoard: FC<MemoryBoardProps> = ({ task }) => {
  console.log(task);
  return <div>MemoryBoard</div>;
};
