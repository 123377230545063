import React from 'react';
import { useDispatch } from 'react-redux';
import {
  faHouseChimney,
  faBriefcase,
  faAddressBook,
  faHospital,
} from '@fortawesome/free-solid-svg-icons';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import { Dispatch } from 'redux';

import { logoutUser } from 'Shared/actions/auth';
import { User, USER_TYPES } from 'Shared/types/shared';
import { USER_SUBSCRIPTION_STATUSES } from 'Shared/constants';

import styles from './accountMenu.module.scss';

export interface UseAccountMenuProps {
  authUser: User;
}

export function useAccountMenu({ authUser }: UseAccountMenuProps) {
  const dispatch: Dispatch = useDispatch();
  const shouldShowFullMenu =
    authUser.subscriptionState?.status !== USER_SUBSCRIPTION_STATUSES.CLINICIAN_SETUP &&
    !authUser.user.promptChangePassword;

  const doLogout = async () => {
    try {
      await logoutUser()(dispatch);
    } catch (e: unknown) {
      console.log(e);
    } finally {
      localStorage.removeItem('accessToken');
      document.location.assign('/login');
    }
  };

  const menu = [
    {
      title: authUser.user.type === USER_TYPES.PATIENT ? 'Dashboard' : 'Clinician Dashboard',
      icon: faHouseChimney,
      url: '/dashboard',
      isReactNav: false,
      isNewTab: false,
      classes: '',
    },
    {
      title: 'My Account',
      icon: faIdCard,
      url: '/account',
      isReactNav: false,
      isNewTab: false,
      classes: '',
    },
  ];

  if (authUser.institutionInfo.institutionAdmin) {
    menu.splice(
      menu.length,
      0,
      ...[
        {
          title: 'Enterprise Dashboard',
          icon: faBriefcase,
          url: '/enterprise-dashboard',
          isReactNav: false,
          isNewTab: false,
          classes: '',
        },
        {
          title: 'Users',
          icon: faAddressBook,
          url: '/enterprise-dashboard/users',
          isReactNav: true,
          isNewTab: false,
          classes: styles.enterprise,
        },
        {
          title: 'Institution Settings',
          icon: faHospital,
          url: '/enterprise-dashboard/institution',
          isReactNav: true,
          isNewTab: false,
          classes: styles.enterprise,
        },
      ]
    );
  }

  return {
    menu,
    shouldShowFullMenu,
    handleLogoutClick: (e: React.SyntheticEvent) => {
      e.preventDefault();
      void doLogout();
    },
  };
}
