import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import styles from './header.module.scss';

interface HeaderProps {
  title: string;
  isAudioSequencePlaying: boolean;
  playAudioSequence: () => void;
}

export const Header: FC<HeaderProps> = ({ title, isAudioSequencePlaying, playAudioSequence }) => {
  return (
    <header className={styles.header}>
      <h1 className={styles.title}>
        <FontAwesomeIcon
          icon={faCirclePlay}
          title="Play sound"
          className={classNames(styles.titleIcon, {
            [styles.titleIconDisabled]: isAudioSequencePlaying,
          })}
          onClick={playAudioSequence}
        />
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </h1>
    </header>
  );
};
