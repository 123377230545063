import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Store } from 'redux';

import store from 'Shared/store';
import { Layout } from 'Shared/components/layout';
import { RootState } from 'Shared/types/shared';
import { AudioContextProvider } from 'Shared/context/audioContext';

export const App: FC = () => {
  return (
    <Provider store={store as Store<RootState>}>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <AudioContextProvider>
          <Layout />
        </AudioContextProvider>
      </DndProvider>
    </Provider>
  );
};
