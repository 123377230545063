import React, { FC } from 'react';

import { Header } from './header';
import { Footer } from './footer';
import * as Types from './types';
import { TaskViewComponentProps, useTaskViewComponent } from './useTaskView';

import styles from './index.module.scss';

export const TaskViewComponent: FC<TaskViewComponentProps> = ({ task, goToNextTask, skipTask }) => {
  const { isPlaying, handlePlayAudioSequenceClick, isAnswered, setIsAnswered } =
    useTaskViewComponent({
      task,
    });
  const viewType = task.type as keyof typeof Types;

  return (
    <div className={styles.taskView}>
      <Header
        title={task.instructions.text}
        isAudioSequencePlaying={isPlaying}
        playAudioSequence={handlePlayAudioSequenceClick}
      />
      {React.createElement(Types[viewType], { task, isAnswered, setIsAnswered })}
      <Footer isAnswered={isAnswered} goToNextTask={goToNextTask} skipTask={skipTask} />
    </div>
  );
};
