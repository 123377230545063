import React, { FC } from 'react';
import queryString from 'query-string';
import { Redirect, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { SUPPORT, USER_SUBSCRIPTION_STATUSES } from 'Shared/constants';
import { RouteAccessRestrictions, RouteShape } from 'Shared/constants';
import { User } from 'Shared/types/shared';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { PageError } from 'Shared/components/pageError';

interface EnhancedRouteProps {
  route: RouteShape;
  authUser: User;
}

export const EnhancedRoute: FC<EnhancedRouteProps> = ({
  route: { component: Component, ...routeProps },
  authUser,
}) => {
  const queryParams = useQueryParams();
  const formattedParams = queryString.stringify({
    returnUrl: document.location.pathname + '?' + queryParams.toString(),
  });
  if (routeProps.access === RouteAccessRestrictions.ONLY_PUBLIC && authUser) {
    return <Redirect to="/dashboard" />;
  }
  if (routeProps.access === RouteAccessRestrictions.PRIVATE && !authUser) {
    return <Redirect to={`/login?${formattedParams}`} />;
  }

  //This condition ensures we make redirect only once
  if (
    authUser &&
    authUser.subscriptionState?.status === USER_SUBSCRIPTION_STATUSES.CLINICIAN_SETUP &&
    document.location.pathname !== '/activate/clinician-setup'
  ) {
    return <Redirect to={`/activate/clinician-setup?${formattedParams}`} />;
  }

  return (
    <Route {...routeProps}>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          return (
            <PageError
              error={{
                title: 'Oh no, something is wrong :-/',
                message: `Please reach out to Customer Support at
    <a href="tel:${SUPPORT.PHONE}">${SUPPORT.PHONE_FORMATTED}</a> or
    <a href="mailto:${SUPPORT.EMAIL}">${SUPPORT.EMAIL}</a> to proceed.
    We apologize for the inconvenience.`,
              }}
            />
          );
        }}
      >
        <Component authUser={authUser} />
      </ErrorBoundary>
    </Route>
  );
};
