import React, { Dispatch, FC, SetStateAction } from 'react';
import { Stimulus } from 'App/therapy/session/taskViewComponent/stimulus';

import { TaskView } from 'Shared/types/shared';

interface MemorySequenceProps {
  task: TaskView;
  isAnswered: boolean;
  setIsAnswered: Dispatch<SetStateAction<boolean>>;
}

export const MemorySequence: FC<MemorySequenceProps> = ({ task }) => {
  console.log(task);
  return <div>MemorySequence</div>;
};
