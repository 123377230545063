import { useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

export function useFooter() {
  const modalRef = useRef(null);
  const [isSkipTaskModalShown, setIsSkipTaskModalShown] = useState(false);

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      setIsSkipTaskModalShown(false);
    }
  });

  return {
    modalRef,
    isSkipTaskModalShown,
    showModal: () => {
      setIsSkipTaskModalShown(true);
    },
    hideModal: () => {
      setIsSkipTaskModalShown(false);
    },
  };
}
