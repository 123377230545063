import React, { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';

import { TaskView, TaskViewProduction } from 'Shared/types/shared';

import { Stimulus } from '../../stimulus';

import { Controls } from './controls';
import { useProduction } from './useProduction';

import styles from './index.module.scss';

interface ProductionProps {
  task: TaskViewProduction;
  isAnswered: boolean;
  setIsAnswered: Dispatch<SetStateAction<boolean>>;
}

export const Production: FC<ProductionProps> = ({ task, isAnswered, setIsAnswered }) => {
  const {
    handleStart,
    handleStop,
    isRecording,
    microphoneAccessFailedError,
    audioLevel,
    handleReplay,
    handleRetry,
    handleMarkCorrect,
  } = useProduction({ task, setIsAnswered });

  if (microphoneAccessFailedError) {
    return (
      <div className={classNames(styles.production, styles.error)}>
        <h3 className="h3">
          <FontAwesomeIcon
            icon={faMicrophoneSlash}
            title="No Microphone Access"
            className={styles.micIconError}
          />
          Microphone access is required to practise this type of exercise. Please allow it and
          refresh the page
        </h3>
      </div>
    );
  }

  return (
    <div className={styles.production}>
      <Controls
        isAnswered={isAnswered}
        isRecording={isRecording}
        startRecording={handleStart}
        stopRecording={handleStop}
        audioLevel={audioLevel}
        handleReplay={handleReplay}
        handleRetry={handleRetry}
        handleMarkCorrect={handleMarkCorrect}
      />
      <Stimulus task={task} />
    </div>
  );
};
