import React, { Dispatch, FC, SetStateAction } from 'react';

import { TaskView } from 'Shared/types/shared';

interface SequenceCompletionProps {
  task: TaskView;
  isAnswered: boolean;
  setIsAnswered: Dispatch<SetStateAction<boolean>>;
}

export const SequenceCompletion: FC<SequenceCompletionProps> = ({ task }) => {
  console.log(task);
  return <div>SequenceCompletion</div>;
};
