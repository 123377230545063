import React, { FC } from 'react';
import classNames from 'classnames';
import { Field, reduxForm, InjectedFormProps, getFormValues } from 'redux-form';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { useSelector } from 'react-redux';

import { ProgressBar } from 'Shared/components/form/wizard/progressBar';
import { FloatingPage } from 'Shared/components/layout/floatingPage';

import { PATIENT_SIGNUP_FORM_NAME, PatientSignupForm } from '../../constants';

import styles from './extra.module.scss';
import sharedStyles from 'App/signup/wizard/shared.module.scss';

const phoneLabel = 'Phone number <i>(for support)</i>';
const clinicianInfoLabel =
  'Who is your clinician and where are they located? ' + '<i>(Optional)</i>';
const clinicianEmailLabel =
  'To invite your clinician to manage your care, enter their userID' +
  ' or email below <i>(Optional)</i>';
const clinicianAssistedLabel =
  'Did a <b>speech therapist</b> or other clinician recommend' + ' Constant Therapy?';
const clinicianAssistedOptions = [
  {
    value: '1',
    label: 'Yes',
  },
  {
    value: '0',
    label: 'No',
  },
];

interface ExtraComponentProps {
  previousPage: () => void;
  totalSteps: number;
}

const ExtraComponent: FC<ExtraComponentProps & InjectedFormProps<object, ExtraComponentProps>> = ({
  handleSubmit,
  previousPage,
  totalSteps,
  error,
  pristine,
  submitting,
}) => {
  const formValues = useSelector(getFormValues(PATIENT_SIGNUP_FORM_NAME)) as PatientSignupForm;

  return (
    <FloatingPage
      customClasses={classNames(sharedStyles.wizardPage, styles.patientExtra)}
      header={
        <>
          <ProgressBar currentStep={3} totalSteps={totalSteps} />
          <h1 className="h1">
            Let us help you get the most from <b>therapy</b>
          </h1>
        </>
      }
    >
      <CTUI.Form.Form
        handleSubmit={handleSubmit}
        error={error}
        customClasses={styles.signupForm}
        actions={
          <>
            <CTUI.Button
              text="&larr; Back"
              variant="primary-inverted"
              customClasses={sharedStyles.button}
              onClick={previousPage}
              disabled={submitting}
              type="button"
            />
            <CTUI.Form.SubmitButton
              text="Finish"
              customClasses={sharedStyles.button}
              isSubmitting={submitting}
              isPristine={pristine}
            />
          </>
        }
      >
        <Field name="phoneNumber" component={CTUI.Form.Field} type="text" label={phoneLabel} />
        <Field
          name="clinicianAssisted"
          component={CTUI.Form.RadioGroup}
          type="radioGroup"
          customClasses={styles.customizedSelector}
          options={clinicianAssistedOptions}
          label={clinicianAssistedLabel}
        />
        {formValues.clinicianAssisted && formValues.clinicianAssisted === '1' && (
          <>
            <Field
              name="clinicianInfo"
              component={CTUI.Form.Field}
              type="text"
              label={clinicianInfoLabel}
              placeholder="Jane Smith, Health South, Boston"
            />
            <Field
              name="clinicianEmail"
              component={CTUI.Form.Field}
              type="text"
              label={clinicianEmailLabel}
            />
          </>
        )}
      </CTUI.Form.Form>
    </FloatingPage>
  );
};

const Extra = reduxForm<object, ExtraComponentProps>({
  form: PATIENT_SIGNUP_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ExtraComponent);

export { Extra };
