import { RouteAccessRestrictions } from 'Shared/constants';

import { Download } from './download';
import { Start } from './start';
import { Survey } from './survey';
import { EnterpriseDashboard } from './enterpriseDashboard';
import { Activate } from './activate';
import { Pages } from './pages';
import { Subscribe } from './subscribe';
import { Dashboard } from './dashboard';
import { Account } from './account';
import { Unsubscribe } from './unsubscribe';
import { ForgotPassword } from './forgotPassword';
import { DoNotContact } from './doNotContact';
import { PaymentUpdate } from './paymentUpdate';
import { Retrial } from './retrial';
import { Signup } from './signup';
import { Login } from './login';
import { Therapy } from './therapy';

export const routes = [
  {
    path: '/',
    component: Pages,
    access: RouteAccessRestrictions.PUBLIC,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: true,
  },
  {
    path: '/login/:action',
    component: Login,
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: false,
  },
  {
    path: '/signup',
    component: Signup,
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: false,
  },
  {
    path: '/download',
    component: Download,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/download-clinician',
    component: Download,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/download-patient',
    component: Download,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/start',
    component: Start,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/survey',
    component: Survey,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/enterprise-dashboard',
    component: EnterpriseDashboard,
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
  },
  {
    path: '/activate',
    component: Activate,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/therapist-consult',
    component: Pages,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/subscribe',
    component: Subscribe,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/account',
    component: Account,
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
  },
  {
    path: '/unsubscribe',
    component: Unsubscribe,
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/do-not-contact',
    component: DoNotContact,
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
  },
  {
    path: '/payment_update',
    component: PaymentUpdate,
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
  },
  {
    path: '/retrial',
    component: Retrial,
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: false,
  },
  {
    path: '/do-therapy',
    component: Therapy,
    access: RouteAccessRestrictions.PRIVATE,
    exact: true,
  },
];
