import React, { createContext, FC, ReactNode, useRef, useState, useEffect } from 'react';

interface AudioContextProps {
  isPlaying: boolean;
  playAudioSequence: ({ audioElementsList }: { audioElementsList: HTMLAudioElement[] }) => void;
  stopAudioSequence: () => void;
}

export const AudioContext = createContext<AudioContextProps>(null);

interface AudioContextProviderProps {
  children: ReactNode;
}

export const AudioContextProvider: FC<AudioContextProviderProps> = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [storedAudioElementsList, setStoredAudioElementsList] = useState<HTMLAudioElement[]>(null);

  //Important: This block is needed to keep the list of audio elements in memory during provider re-renders
  const storedAudioElementsListRef = useRef<HTMLAudioElement[]>(null);
  useEffect(() => {
    storedAudioElementsListRef.current = storedAudioElementsList;
  }, [storedAudioElementsList]);

  const playAudio = (
    audioElement: HTMLAudioElement,
    index: number,
    audioElementsList: HTMLAudioElement[]
  ) => {
    const nextIndex = index + 1;

    if (nextIndex < audioElementsList.length) {
      audioElement.addEventListener('ended', () => {
        playAudio(audioElementsList[nextIndex], nextIndex, audioElementsList);
      });
    } else {
      audioElement.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    }

    // @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
    // We need to check for this state in order to decide whether we want to play audio right away
    // or wait for a proper event.
    // This logic catches the case when `canplaythrough` event was fired before we actually got to this condition
    if (audioElement.readyState === 4) {
      void audioElement.play();
    } else {
      audioElement.addEventListener('canplaythrough', () => {
        void audioElement.play();
      });
    }
  };

  const playAudioSequence = ({ audioElementsList }: { audioElementsList: HTMLAudioElement[] }) => {
    setStoredAudioElementsList(audioElementsList);
    setIsPlaying(true);
    // Because of the asynchronous nature of React's `setState` function we have to pass the
    // `audioElementsList` as an argument rather than referencing it from the state
    playAudio(audioElementsList[0], 0, audioElementsList);
  };

  const stopAudioSequence = () => {
    setIsPlaying(false);
    storedAudioElementsListRef.current?.forEach((audioElement) => {
      audioElement.pause();
    });
  };

  return (
    <AudioContext.Provider value={{ isPlaying, playAudioSequence, stopAudioSequence }}>
      {children}
    </AudioContext.Provider>
  );
};
