import { Dispatch } from 'redux';
import queryString from 'query-string';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import { SCHEDULED_SESSION, THERAPY_GET_ACTIVITY, THERAPY_COMPLETE_TASK } from 'Shared/types/redux';
import { TherapyActivity, TherapySessionResponse } from 'Shared/types/api';
import { TaskDoneRequestBody } from 'Shared/types/task';

export const getActivity =
  ({ patientId }: { patientId: number }) =>
  async (dispatch: Dispatch) => {
    return (await fetchWrapper.get(`${getBaseUrl()}/api/patient/${patientId}/nextActivity`, {
      dispatch,
      types: THERAPY_GET_ACTIVITY,
    })) as TherapyActivity;
  };

export const startScheduledSession =
  ({ patientId, scheduleId }: { patientId: number; scheduleId: number }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
        scheduleId,
        os: 1,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await fetchWrapper.get(
      `${getBaseUrl()}/api/patient/${patientId}/session/start/SCHEDULED?${formattedParams}`,
      { dispatch, types: SCHEDULED_SESSION }
    )) as TherapySessionResponse;
  };

export const completeTask =
  ({ patientId, requestBody }: { patientId: number; requestBody: TaskDoneRequestBody[] }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      {
        token: localStorage.getItem('accessToken'),
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.post(
      `${getBaseUrl()}/api/patient/${patientId}/responses?${formattedParams}`,
      { dispatch, types: THERAPY_COMPLETE_TASK },
      requestBody
    );
  };
