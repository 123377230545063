import React, { CSSProperties, Dispatch, FC, SetStateAction, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { User } from 'Shared/types/shared';

import { useAccountMenu } from './useAccountMenu';

import styles from './accountMenu.module.scss';

interface AccountMenuProps {
  authUser: User;
  popper: {
    ref: Dispatch<SetStateAction<HTMLElement>>;
    style: CSSProperties;
    attributes: object;
  };
  toggleMenuVisibility: (e: SyntheticEvent) => void;
}

export const AccountMenu: FC<AccountMenuProps> = ({
  authUser,
  popper: { ref, style, attributes },
  toggleMenuVisibility,
}) => {
  const { menu, shouldShowFullMenu, handleLogoutClick } = useAccountMenu({
    authUser,
  });

  return (
    <ul
      className={styles.accountMenu}
      ref={ref}
      style={style}
      {...attributes}
      onClick={toggleMenuVisibility}
    >
      {shouldShowFullMenu &&
        menu.map((item) => {
          const itemClasses = classNames(styles.item, item.classes);

          return (
            <li className={itemClasses} key={item.title}>
              {item.isNewTab ? (
                <a href={item.url} className={styles.link} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={item.icon} className={styles.icon} />
                  {item.title}
                </a>
              ) : (
                <Link to={item.url} className={styles.link}>
                  <FontAwesomeIcon icon={item.icon} className={styles.icon} />
                  {item.title}
                </Link>
              )}
            </li>
          );
        })}
      <li className={styles.item} key="logout">
        <a href="/logout" className={styles.link} onClick={handleLogoutClick}>
          <FontAwesomeIcon icon={faSignOut} className={styles.icon} />
          Logout
        </a>
      </li>
    </ul>
  );
};
