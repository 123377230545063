export const SELECT_PATIENT_TYPE = 'SELECT_PATIENT_TYPE';

export const CLEAR_PATIENT_TYPE = 'CLEAR_PATIENT_TYPE';

export enum SAVE_NEW_PATIENT {
  start = 'SAVE_NEW_PATIENT_START',
  success = 'SAVE_NEW_PATIENT_SUCCESS',
  fail = 'SAVE_NEW_PATIENT_FAIL',
}

export enum SAVE_EXISTING_PATIENT {
  start = 'SAVE_EXISTING_PATIENT_START',
  success = 'SAVE_EXISTING_PATIENT_SUCCESS',
  fail = 'SAVE_EXISTING_PATIENT_FAIL',
}

export enum DO_USER_LOGIN {
  start = 'DO_USER_LOGIN_START',
  success = 'DO_USER_LOGIN_SUCCESS',
  fail = 'DO_USER_LOGIN_FAIL',
}

export enum OTP_VALIDATE {
  start = 'OTP_VALIDATE_START',
  success = 'OTP_VALIDATE_SUCCESS',
  fail = 'OTP_VALIDATE_FAIL',
}

export enum OTP_RESET {
  start = 'OTP_RESET_START',
  success = 'OTP_RESET_SUCCESS',
  fail = 'OTP_RESET_FAIL',
}

export const DO_USER_LOGOUT_SUCCESS = 'DO_USER_LOGOUT_SUCCESS';

export enum GET_DASHBOARD_DATA {
  start = 'GET_DASHBOARD_DATA_START',
  success = 'GET_DASHBOARD_DATA_SUCCESS',
  fail = 'GET_DASHBOARD_DATA_FAIL',
}

export enum GET_DASHBOARD_PATIENT_EXPANSION {
  start = 'GET_DASHBOARD_PATIENT_EXPANSION_START',
  success = 'GET_DASHBOARD_PATIENT_EXPANSION_SUCCESS',
  fail = 'GET_DASHBOARD_PATIENT_EXPANSION_FAIL',
}

export enum LOG_EVENT {
  start = 'LOG_EVENT_START',
  success = 'LOG_EVENT_SUCCESS',
  fail = 'LOG_EVENT_FAIL',
}

export const FLINT_ACTIVATION_SET_SUBSCRIPTION_TIME = 'FLINT_ACTIVATION_SET_SUBSCRIPTION_TIME';
export const FLINT_ACTIVATION_SET_FIRST_NAME = 'FLINT_ACTIVATION_SET_FIRST_NAME';
export const FLINT_ACTIVATION_SET_LAST_NAME = 'FLINT_ACTIVATION_SET_LAST_NAME';
export const FLINT_ACTIVATION_SET_EMAIL = 'FLINT_ACTIVATION_SET_EMAIL';

export enum FLINT_ACTIVATION_ACTIVATE_PATIENT {
  start = 'FLINT_ACTIVATION_ACTIVATE_PATIENT_START',
  success = 'FLINT_ACTIVATION_ACTIVATE_PATIENT_SUCCESS',
  fail = 'FLINT_ACTIVATION_ACTIVATE_PATIENT_FAIL',
}
export enum GET_INSTITUTION {
  start = 'GET_INSTITUTION_START',
  success = 'GET_INSTITUTION_SUCCESS',
  fail = 'GET_INSTITUTION_FAIL',
}

export enum GET_USERS {
  start = 'GET_USERS_START',
  success = 'GET_USERS_SUCCESS',
  fail = 'GET_USERS_FAIL',
}

export enum INSTITUTION_RESET_USER_PASSWORD {
  start = 'INSTITUTION_RESET_USER_PASSWORD_START',
  success = 'INSTITUTION_RESET_USER_PASSWORD_SUCCESS',
  fail = 'INSTITUTION_RESET_USER_PASSWORD_FAIL',
}

export enum SUSPEND_USER {
  start = 'SUSPEND_USER_START',
  success = 'SUSPEND_USER_SUCCESS',
  fail = 'SUSPEND_USER_FAIL',
}

export enum ACTIVATE_USER {
  start = 'ACTIVATE_USER_START',
  success = 'ACTIVATE_USER_SUCCESS',
  fail = 'ACTIVATE_USER_FAIL',
}

export enum REMOVE_USER {
  start = 'REMOVE_USER_START',
  success = 'REMOVE_USER_SUCCESS',
  fail = 'REMOVE_USER_FAIL',
}

export enum DISCHARGE_USER {
  start = 'DISCHARGE_USER_START',
  success = 'DISCHARGE_USER_SUCCESS',
  fail = 'DISCHARGE_USER_FAIL',
}

export enum INVITE_USER {
  start = 'INVITE_USER_START',
  success = 'INVITE_USER_SUCCESS',
  fail = 'INVITE_USER_FAIL',
}

export enum RE_INVITE_USER {
  start = 'RE_INVITE_USER_START',
  success = 'RE_INVITE_USER_SUCCESS',
  fail = 'RE_INVITE_USER_FAIL',
}

export enum SAVE_INSTITUTION {
  start = 'SAVE_INSTITUTION_START',
  success = 'SAVE_INSTITUTION_SUCCESS',
  fail = 'SAVE_INSTITUTION_FAIL',
}

export enum GET_PASSWORD_REQUIREMENTS {
  start = 'GET_PASSWORD_REQUIREMENTS_START',
  success = 'GET_PASSWORD_REQUIREMENTS_SUCCESS',
  fail = 'GET_PASSWORD_REQUIREMENTS_FAIL',
}

export const FILTER_USERS = 'FILTER_USERS';

export enum GET_PATIENT_REPORT {
  start = 'GET_PATIENT_REPORT_START',
  success = 'GET_PATIENT_REPORT_SUCCESS',
  fail = 'GET_PATIENT_REPORT_FAIL',
}

export enum GET_PATIENT_CALENDAR {
  start = 'GET_PATIENT_CALENDAR_START',
  success = 'GET_PATIENT_CALENDAR_SUCCESS',
  fail = 'GET_PATIENT_CALENDAR_FAIL',
}

export enum ACTIVATE_PATIENT_HOMEWORK_INVITATION {
  start = 'ACTIVATE_PATIENT_HOMEWORK_INVITATION_START',
  success = 'ACTIVATE_PATIENT_HOMEWORK_INVITATION_SUCCESS',
  fail = 'ACTIVATE_PATIENT_HOMEWORK_INVITATION_FAIL',
}

export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';
export const CLEAR_FLASH_MESSAGE = 'CLEAR_FLASH_MESSAGE';

export enum VALIDATE_EMAIL {
  start = 'VALIDATE_EMAIL_START',
  success = 'VALIDATE_EMAIL_SUCCESS',
  fail = 'VALIDATE_EMAIL_FAIL',
}

export enum VALIDATE_USERNAME {
  start = 'VALIDATE_USERNAME_START',
  success = 'VALIDATE_USERNAME_SUCCESS',
  fail = 'VALIDATE_USERNAME_FAIL',
}

export enum GET_SUBSCRIPTION_SESSION {
  start = 'GET_SUBSCRIPTION_SESSION_START',
  success = 'GET_SUBSCRIPTION_SESSION_SUCCESS',
  fail = 'GET_SUBSCRIPTION_SESSION_FAIL',
}

export enum GET_SUBSCRIPTION_PLANS {
  start = 'GET_SUBSCRIPTION_PLANS_START',
  success = 'GET_SUBSCRIPTION_PLANS_SUCCESS',
  fail = 'GET_SUBSCRIPTION_PLANS_FAIL',
}

export enum GET_SUBSCRIPTION_CONFIRMATION {
  start = 'GET_SUBSCRIPTION_CONFIRMATION_START',
  success = 'GET_SUBSCRIPTION_CONFIRMATION_SUCCESS',
  fail = 'GET_SUBSCRIPTION_CONFIRMATION_FAIL',
}

export enum RETRIAL {
  start = 'RETRIAL_START',
  success = 'RETRIAL_SUCCESS',
  fail = 'RETRIAL_FAIL',
}

export enum UPDATE_PAYMENT_DETAILS {
  start = 'UPDATE_PAYMENT_DETAILS_START',
  success = 'UPDATE_PAYMENT_DETAILS_SUCCESS',
  fail = 'UPDATE_PAYMENT_DETAILS_FAIL',
}

export enum REACTIVATE_SUBSCRIPTION {
  start = 'REACTIVATE_SUBSCRIPTION_START',
  success = 'REACTIVATE_SUBSCRIPTION_SUCCESS',
  fail = 'REACTIVATE_SUBSCRIPTION_FAIL',
}

export enum CANCEL_SUBSCRIPTION {
  start = 'CANCEL_SUBSCRIPTION_START',
  success = 'CANCEL_SUBSCRIPTION_SUCCESS',
  fail = 'CANCEL_SUBSCRIPTION_FAIL',
}

export enum SAVE_SURVEY_CAMPAIGN {
  start = 'SAVE_SURVEY_CAMPAIGN_START',
  success = 'SAVE_SURVEY_CAMPAIGN_SUCCESS',
  fail = 'SAVE_SURVEY_CAMPAIGN_FAIL',
}

export enum GET_USER_INSTITUTIONS {
  start = 'GET_USER_INSTITUTIONS_START',
  success = 'GET_USER_INSTITUTIONS_SUCCESS',
  fail = 'GET_USER_INSTITUTIONS_FAIL',
}

export enum ACCEPT_INVITATION {
  start = 'ACCEPT_INVITATION_START',
  success = 'ACCEPT_INVITATION_SUCCESS',
  fail = 'ACCEPT_INVITATION_FAIL',
}

export enum INSTITUTION_GET_PASSWORD_REQUIREMENTS {
  start = 'INSTITUTION_GET_PASSWORD_REQUIREMENTS_START',
  success = 'INSTITUTION_GET_PASSWORD_REQUIREMENTS_SUCCESS',
  fail = 'INSTITUTION_GET_PASSWORD_REQUIREMENTS_FAIL',
}

export enum CHANGE_PASSWORD {
  start = 'CHANGE_PASSWORD_START',
  success = 'CHANGE_PASSWORD_SUCCESS',
  fail = 'CHANGE_PASSWORD_FAIL',
}

export enum GET_USER_DATA {
  start = 'GET_USER_DATA_START',
  success = 'GET_USER_DATA_SUCCESS',
  fail = 'GET_USER_DATA_FAIL',
}

export enum GET_USER_DATA_NA {
  start = 'GET_USER_DATA_NA_START',
  success = 'GET_USER_DATA_NA_SUCCESS',
  fail = 'GET_USER_DATA_NA_FAIL',
}

export enum GET_USER_SUBSCRIPTION {
  start = 'GET_USER_SUBSCRIPTION_START',
  success = 'GET_USER_SUBSCRIPTION_SUCCESS',
  fail = 'GET_USER_SUBSCRIPTION_FAIL',
}

export enum GET_UNSUBSCRIBE_REASONS {
  start = 'GET_UNSUBSCRIBE_REASONS_START',
  success = 'GET_UNSUBSCRIBE_REASONS_SUCCESS',
  fail = 'GET_UNSUBSCRIBE_REASONS_FAIL',
}

export enum GET_USER_LOCATION {
  start = 'GET_USER_LOCATION_START',
  success = 'GET_USER_LOCATION_SUCCESS',
  fail = 'GET_USER_LOCATION_FAIL',
}

export enum RESET_USER_PASSWORD {
  start = 'RESET_USER_PASSWORD_START',
  success = 'RESET_USER_PASSWORD_SUCCESS',
  fail = 'RESET_USER_PASSWORD_FAIL',
}

export enum DO_NOT_CONTACT {
  start = 'DO_NOT_CONTACT_START',
  success = 'DO_NOT_CONTACT_SUCCESS',
  fail = 'DO_NOT_CONTACT_FAIL',
}

export enum SAVE_USER_INFO {
  start = 'SAVE_USER_INFO_START',
  success = 'SAVE_USER_INFO_SUCCESS',
  fail = 'SAVE_USER_INFO_FAIL',
}

export const CLEAR_USER_SUBSCRIPTION_CACHE = 'CLEAR_USER_SUBSCRIPTION_CACHE';

export enum GET_PATIENT_SCHEDULE {
  start = 'GET_PATIENT_SCHEDULE_START',
  success = 'GET_PATIENT_SCHEDULE_SUCCESS',
  fail = 'GET_PATIENT_SCHEDULE_FAIL',
}

export enum SAVE_PATIENT_SCHEDULE {
  start = 'SAVE_PATIENT_SCHEDULE_START',
  success = 'SAVE_PATIENT_SCHEDULE_SUCCESS',
  fail = 'SAVE_PATIENT_SCHEDULE_FAIL',
}

export enum GENERATE_INITIAL_PATIENT_SCHEDULE {
  start = 'GENERATE_INITIAL_PATIENT_SCHEDULE_START',
  success = 'GENERATE_INITIAL_PATIENT_SCHEDULE_SUCCESS',
  fail = 'GENERATE_INITIAL_PATIENT_SCHEDULE_FAIL',
}

export enum GET_ALL_TASKS {
  start = 'GET_ALL_TASKS_START',
  success = 'GET_ALL_TASKS_SUCCESS',
  fail = 'GET_ALL_TASKS_FAIL',
}

export const SIGNUP_SELECT_USER_TYPE = 'SIGNUP_SELECT_USER_TYPE';
export const SIGNUP_CLEAR_USER_TYPE = 'SIGNUP_CLEAR_USER_TYPE';
export const SIGNUP_SET_EMAIL = 'SIGNUP_SET_EMAIL';
export const SIGNUP_SET_INSTITUTION_ID = 'SIGNUP_SET_INSTITUTION_ID';
export const SIGNUP_SET_INVITATION_TOKEN = 'SIGNUP_SET_INVITATION_TOKEN';
export const SIGNUP_SET_SOURCE = 'SIGNUP_SET_SOURCE';

export enum SIGNUP_CLINICIAN_SIGNUP {
  start = 'SIGNUP_CLINICIAN_SIGNUP_START',
  success = 'SIGNUP_CLINICIAN_SIGNUP_SUCCESS',
  fail = 'SIGNUP_CLINICIAN_SIGNUP_FAIL',
}

export enum SIGNUP_PATIENT_SIGNUP {
  start = 'SIGNUP_PATIENT_SIGNUP_START',
  success = 'SIGNUP_PATIENT_SIGNUP_SUCCESS',
  fail = 'SIGNUP_PATIENT_SIGNUP_FAIL',
}

export enum THERAPY_GET_ACTIVITY {
  start = 'THERAPY_GET_ACTIVITY_START',
  success = 'THERAPY_GET_ACTIVITY_SUCCESS',
  fail = 'THERAPY_GET_ACTIVITY_FAIL',
}

export enum SCHEDULED_SESSION {
  start = 'SCHEDULED_SESSION_START',
  success = 'SCHEDULED_SESSION_SUCCESS',
  fail = 'SCHEDULED_SESSION_FAIL',
}

export enum THERAPY_COMPLETE_TASK {
  start = 'THERAPY_COMPLETE_TASK_START',
  success = 'THERAPY_COMPLETE_TASK_SUCCESS',
  fail = 'THERAPY_COMPLETE_TASK_FAIL',
}
