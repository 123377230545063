import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

import { TextStimulus } from 'Shared/types/shared';

import { useChoices, ChoicesProps } from './useChoices';

import styles from './index.module.scss';

export const Choices: FC<ChoicesProps> = ({ task, isAnswered, setIsAnswered }) => {
  const { answerTrigger, handleResponse } = useChoices({ task, isAnswered, setIsAnswered });

  return (
    <div className={styles.choices}>
      {task.questions[0].choices.map((choice) => {
        const buttonProps = {
          text: (choice.stimulus as TextStimulus).text,
          value: choice.value,
          customClasses: classNames(styles.button, {
            [styles.answerTriggerCorrect]:
              isAnswered && choice.value === answerTrigger && choice.correct,
            [styles.answerTriggerWrong]:
              isAnswered && choice.value === answerTrigger && !choice.correct,
          }),
          onClick: handleResponse,
          disabled: isAnswered,
          ...(isAnswered && {
            icon: choice.correct ? faCircleCheck : faCircleXmark,
          }),
        };
        return <CTUI.Button key={choice.value} {...buttonProps} />;
      })}
    </div>
  );
};
