import React, { FC } from 'react';

import { User, USER_TYPES } from 'Shared/types/shared';
import { PageError } from 'Shared/components/pageError';

import { useTherapy } from './useTherapy';
import { Session } from './session';
import { Welcome } from './welcome';

interface TherapyProps {
  authUser: User;
}

export const Therapy: FC<TherapyProps> = ({ authUser }) => {
  const { scheduleId, setScheduleId } = useTherapy();

  if (authUser.user.type !== USER_TYPES.PATIENT) {
    return (
      <PageError
        error={{ title: 'Access denied', message: 'This section is available for patients only!' }}
      />
    );
  }

  if (scheduleId) {
    return <Session authUser={authUser} scheduleId={scheduleId} />;
  }

  return <Welcome authUser={authUser} setScheduleId={setScheduleId} />;
};
