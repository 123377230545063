import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import queryString from 'query-string';

import { getUserData, verifyToken } from 'Shared/actions/user';
import { RootState } from 'Shared/types/shared';

interface QueryParamsProps {
  token?: string;
}

export function useLayout() {
  const dispatch: Dispatch = useDispatch();
  const queryParams: QueryParamsProps = queryString.parse(window.location.search);
  const [isLoading, setIsLoading] = useState(true);
  const authUser = useSelector((state: RootState) => state.user.data);

  const fetchUserData = async (token?: string) => {
    try {
      if (token) {
        // Make sure that token passed via url-params is valid
        const response = await verifyToken(token)(dispatch);
        if (response) {
          // Set or replace token in localStorage if we got a valid response
          localStorage.setItem('accessToken', token);
        }
      }
      await getUserData(token)(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchUserData(queryParams.token);
  }, [queryParams.token]);

  return { isLoading, authUser };
}
