import React, { FC } from 'react';
import classNames from 'classnames';

import { Spinner } from 'Shared/components/spinner';

import { ProgressBar } from './progressBar';
import { TaskViewComponent } from './taskViewComponent';
import { SessionProps, useSession } from './useSession';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const Session: FC<SessionProps> = ({ authUser, scheduleId }) => {
  const { isLoading, therapySession, taskTypes, activeTaskIndex, goToNextTask, skipTask } =
    useSession({
      authUser,
      scheduleId,
    });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classNames(sharedStyles.therapy, styles.session)}>
      <TaskViewComponent
        task={therapySession.taskViews[activeTaskIndex]}
        goToNextTask={goToNextTask}
        skipTask={skipTask}
      />
      <ProgressBar
        therapySession={therapySession}
        taskTypes={taskTypes}
        activeTaskIndex={activeTaskIndex}
      />
    </div>
  );
};
