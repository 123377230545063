import { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '@thelearningcorp/ct-web-packages';

import { HtmlStimulus } from 'Shared/types/shared';

export interface HtmlContentProps {
  stimulus: HtmlStimulus;
  resourceUrl: string;
}

export function useHtml({ stimulus, resourceUrl }: HtmlContentProps) {
  const modalRef = useRef(null);
  const [isFullscreenPreviewShown, setIsFullscreenPreviewShown] = useState(false);

  const [htmlOriginal, setHtmlOriginal] = useState<string>();
  const [htmlReplaced, setHtmlReplaced] = useState<string>();

  const loadAndReplaceHtml = async () => {
    try {
      const responsePromise = await fetch(resourceUrl + stimulus.htmlPath);
      const html = await responsePromise.text();
      setHtmlOriginal(html);
      const wReplacedKeys = Object.entries(stimulus.htmlQuestionReplacementTokens).reduce(
        (acc, [key, value]) => acc.replaceAll(key, value),
        html
      );
      //This is needed to vertically align svg-image inside its container
      const wReplacedKeysNStyles = wReplacedKeys.replace(
        '<body>',
        '<body><style>html, body {height: 100%; overflow: hidden;}</style>'
      );
      setHtmlReplaced(wReplacedKeysNStyles);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  useClickOutside(modalRef, () => {
    if (modalRef.current) {
      setIsFullscreenPreviewShown(false);
    }
  });

  useEffect(() => {
    void loadAndReplaceHtml();
  }, []);

  return {
    htmlOriginal,
    htmlReplaced,
    modalRef,
    isFullscreenPreviewShown,
    showFullscreenPreview: () => {
      setIsFullscreenPreviewShown(true);
    },
    hideFullscreenPreview: () => {
      setIsFullscreenPreviewShown(false);
    },
  };
}
