import React, { Dispatch, FC, SetStateAction } from 'react';

import { TaskViewNumberProblemSolving } from 'Shared/types/shared';

import { Stimulus } from '../../stimulus';

import { Controls } from './controls';

import styles from './index.module.scss';

interface NumberProblemSolvingProps {
  task: TaskViewNumberProblemSolving;
  isAnswered: boolean;
  setIsAnswered: Dispatch<SetStateAction<boolean>>;
}

export const NumberProblemSolving: FC<NumberProblemSolvingProps> = ({ task }) => {
  return (
    <div className={styles.numberProblemSolving}>
      <Controls showCalculator={task.showCalculator} showScratchpad={task.showScratchpad} />
      <Stimulus task={task} />
    </div>
  );
};
