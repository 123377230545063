import { useCallback, useContext, useEffect, useState } from 'react';

import { AudioContext } from 'Shared/context/audioContext';
import type { TaskView } from 'Shared/types/shared';
import { SkipReason, SkipType } from 'Shared/types/task';

export interface TaskViewComponentProps {
  task: TaskView;
  goToNextTask?: () => void;
  skipTask?: ({ type, reason }: { type: SkipType; reason: SkipReason }) => Promise<void>;
}

export function useTaskViewComponent({ task }: TaskViewComponentProps) {
  const { isPlaying, playAudioSequence, stopAudioSequence } = useContext(AudioContext);

  const [isAnswered, setIsAnswered] = useState(false);

  const handlePlayAudioSequenceClick = useCallback(() => {
    playAudioSequence({
      audioElementsList: task.instructions.instructionAudioPaths.map((instructionPath) => {
        return new Audio(task.resourceUrl + instructionPath);
      }),
    });
  }, [task]);

  useEffect(() => {
    if (task.instructions.autoplayInstructions) {
      handlePlayAudioSequenceClick();

      return stopAudioSequence;
    }
  }, [task]);

  //Reset state on a task change
  useEffect(() => {
    setIsAnswered(false);
  }, [task]);

  return {
    isAnswered,
    setIsAnswered,
    isPlaying,
    handlePlayAudioSequenceClick,
  };
}
