import React, { Dispatch, FC, SetStateAction } from 'react';
import { Stimulus } from 'App/therapy/session/taskViewComponent/stimulus';
import { Choices } from 'App/therapy/session/taskViewComponent/types/MultipleChoice/choices';

import { TaskViewMultipleChoice } from 'Shared/types/shared';

import styles from './index.module.scss';

interface MultipleChoiceProps {
  task: TaskViewMultipleChoice;
  isAnswered: boolean;
  setIsAnswered: Dispatch<SetStateAction<boolean>>;
}

export const MultipleChoice: FC<MultipleChoiceProps> = ({ task, isAnswered, setIsAnswered }) => {
  return (
    <div className={styles.multipleChoice}>
      <Choices task={task} isAnswered={isAnswered} setIsAnswered={setIsAnswered} />
      <Stimulus task={task} />
    </div>
  );
};
