import { useDispatch } from 'react-redux';
import { type Dispatch as ReduxDispatch } from 'redux';
import { type Dispatch as ReactDispatch, SetStateAction, SyntheticEvent, useState } from 'react';

import { setFlashMessage } from 'Shared/actions/service';
import { FlashMessageType, TaskViewMultipleChoice } from 'Shared/types/shared';

export interface ChoicesProps {
  task: TaskViewMultipleChoice;
  isAnswered: boolean;
  setIsAnswered: ReactDispatch<SetStateAction<boolean>>;
}

export function useChoices({ task, isAnswered, setIsAnswered }: ChoicesProps) {
  const dispatch: ReduxDispatch = useDispatch();
  const [answerTrigger, setAnswerTrigger] = useState<string>();

  const handleResponse = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    e.preventDefault();
    const choiceSelected = task.questions[0].choices.find(
      (choice) => choice.value === e.target.value
    );

    if (choiceSelected && choiceSelected.correct) {
      setFlashMessage({
        message: 'Nice Job, your answer is correct!',
        type: FlashMessageType.SUCCESS,
      })(dispatch);
    } else {
      setFlashMessage({
        message: 'Uh oh, your answer is wrong.',
        type: FlashMessageType.ERROR,
      })(dispatch);
    }

    setAnswerTrigger(e.target.value);
    setIsAnswered(true);
  };

  return {
    handleResponse,
    answerTrigger,
  };
}
