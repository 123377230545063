import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Spinner } from 'Shared/components/spinner';

import { WelcomeProps, useWelcome } from './useWelcome';

import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

export const Welcome: FC<WelcomeProps> = ({ authUser, setScheduleId }) => {
  const { isLoading, therapyActivity, handleStartSession } = useWelcome({
    authUser,
    setScheduleId,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classNames(sharedStyles.therapy, styles.welcome)}>
      <div className={styles.greetings}>
        <div className={styles.left}>
          <img
            src={`${therapyActivity.resourceUrl + therapyActivity.screenData.imagePath}`}
            className={styles.cathy}
          />
        </div>
        <div className={styles.right}>
          <h2
            className="h2"
            dangerouslySetInnerHTML={{ __html: therapyActivity.screenData.messageText1 }}
          />
          <p dangerouslySetInnerHTML={{ __html: therapyActivity.screenData.messageText2 }} />
          <CTUI.Button text="Start" customClasses={styles.start} onClick={handleStartSession} />
        </div>
      </div>
      <div className={styles.statistics}>
        <p dangerouslySetInnerHTML={{ __html: therapyActivity.therapyData.daysText }} />
        <ul className={styles.daysList}>
          {therapyActivity.therapyData.daysList.map((day, key) => {
            return (
              <li
                key={key}
                className={classNames(styles.day, {
                  [styles.activeDay]: day,
                  [styles.currentDay]: moment().weekday() === key,
                })}
              >
                {moment().weekday(key).format('ddd')}
              </li>
            );
          })}
        </ul>
        <div className={styles.note}>
          <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
          <span dangerouslySetInnerHTML={{ __html: therapyActivity.therapyData.assessmentText }} />
        </div>
        <div className={styles.accuracy}>
          <b>Accuracy:</b> {therapyActivity.therapyData.accuracy.text}{' '}
          <img
            src={`${therapyActivity.resourceUrl + therapyActivity.therapyData.accuracy.imagePath}`}
            alt={therapyActivity.therapyData.accuracy.text}
            className={styles.emoji}
          />
        </div>
      </div>
    </div>
  );
};
