import React, { FC } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'App/routes';

import { Spinner } from 'Shared/components/spinner';
import { PageError } from 'Shared/components/pageError';
import { BackToTop } from 'Shared/components/layout/backToTop';

import { useLayout } from './useLayout';
import { Footer } from './footer';
import { Header } from './header';
import { FlashMessage } from './flashMessage';
import { EnhancedRoute } from './router/enhancedRoute';

export const Layout: FC = () => {
  const { isLoading, authUser } = useLayout();

  return (
    <BrowserRouter>
      <Header />
      <FlashMessage />
      {isLoading ? (
        <Spinner />
      ) : (
        <Switch>
          {routes.map((route, i) => (
            <EnhancedRoute key={i} route={route} authUser={authUser} {...route} />
          ))}
          <Route path="/reports">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="*">
            <PageError />
          </Route>
        </Switch>
      )}
      <BackToTop />
      <Footer />
    </BrowserRouter>
  );
};
