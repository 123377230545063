import fetch from 'isomorphic-fetch';
import { SubmissionError } from 'redux-form';
import { Dispatch } from 'redux';

import { DEFAULT_ERROR } from './constants';
import { logoutUser } from './actions/auth';

interface ApiResponseWrapper<T> {
  invalidEmail?: string;
  message?: string;
  authError?: string;
  error?: string;
  errors?: {
    message?: string;
  }[];
  ctdata?: T;
  ctmsgs?: object[] | object;
  data?: T;
}

const FETCH_PARAMS_DEFAULTS = {
  method: 'GET',
  mode: 'cors' as const,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const fetchWrapper = {
  get,
  post,
  put,
  patch,
  delete: _delete,
};

interface ActionData {
  dispatch: Dispatch;
  types: {
    start: string;
    success: string;
    fail: string;
  };
}
interface RequestParams {
  skipParams?: boolean;
  isSubmission?: boolean;
  skipAccessToken?: boolean;
}

async function get(
  url: string,
  { dispatch, types: { start, success, fail } }: ActionData,
  { skipParams = false, isSubmission = false, skipAccessToken = false }: RequestParams = {}
) {
  dispatch({ type: start });

  const response = await fetch(
    url,
    skipParams
      ? null
      : {
          ...FETCH_PARAMS_DEFAULTS,
          headers: {
            ...FETCH_PARAMS_DEFAULTS.headers,
            ...(!skipAccessToken && {
              'access-token': localStorage.getItem('accessToken'),
            }),
          },
        }
  );

  try {
    const data = await handleResponse(dispatch, response);
    return dispatchSuccess(dispatch, success, data);
  } catch (e: unknown) {
    dispatchError(dispatch, fail, isSubmission, e.toString());
  }
}

async function post(
  url: string,
  { dispatch, types: { start, success, fail } }: ActionData,
  body?: object,
  { skipParams = false, isSubmission = false, skipAccessToken = false }: RequestParams = {}
) {
  dispatch({ type: start });

  const response = await fetch(
    url,
    skipParams
      ? null
      : {
          ...FETCH_PARAMS_DEFAULTS,
          mode: 'cors',
          method: 'POST',
          headers: {
            ...FETCH_PARAMS_DEFAULTS.headers,
            ...(!skipAccessToken && {
              'access-token': localStorage.getItem('accessToken'),
            }),
          },
          body: JSON.stringify(body),
        }
  );

  try {
    const data = await handleResponse(dispatch, response);
    return dispatchSuccess(dispatch, success, data);
  } catch (e: unknown) {
    dispatchError(dispatch, fail, isSubmission, e.toString());
  }
}

async function put(
  url: string,
  { dispatch, types: { start, success, fail } }: ActionData,
  body?: object,
  { skipParams = false, isSubmission = false }: RequestParams = {}
) {
  dispatch({ type: start });

  const response = await fetch(
    url,
    skipParams
      ? null
      : {
          ...FETCH_PARAMS_DEFAULTS,
          mode: 'cors',
          method: 'PUT',
          headers: {
            ...FETCH_PARAMS_DEFAULTS.headers,
            'access-token': localStorage.getItem('accessToken'),
          },
          body: JSON.stringify(body),
        }
  );

  try {
    const data = await handleResponse(dispatch, response);
    return dispatchSuccess(dispatch, success, data);
  } catch (e: unknown) {
    dispatchError(dispatch, fail, isSubmission, e.toString());
  }
}

async function patch(
  url: string,
  { dispatch, types: { start, success, fail } }: ActionData,
  body?: object,
  { skipParams = false, isSubmission = false }: RequestParams = {}
) {
  dispatch({ type: start });

  const response = await fetch(
    url,
    skipParams
      ? null
      : {
          ...FETCH_PARAMS_DEFAULTS,
          mode: 'cors',
          method: 'PATCH',
          headers: {
            ...FETCH_PARAMS_DEFAULTS.headers,
            'access-token': localStorage.getItem('accessToken'),
          },
          body: JSON.stringify(body),
        }
  );

  try {
    const data = await handleResponse(dispatch, response);
    return dispatchSuccess(dispatch, success, data);
  } catch (e: unknown) {
    dispatchError(dispatch, fail, isSubmission, e.toString());
  }
}

async function _delete(
  url: string,
  { dispatch, types: { start, success, fail } }: ActionData,
  { skipParams = false, isSubmission = false }: RequestParams = {}
) {
  dispatch({ type: start });

  const response = await fetch(
    url,
    skipParams
      ? null
      : {
          ...FETCH_PARAMS_DEFAULTS,
          mode: 'cors',
          method: 'DELETE',
          headers: {
            ...FETCH_PARAMS_DEFAULTS.headers,
            'access-token': localStorage.getItem('accessToken'),
          },
        }
  );

  try {
    const data = await handleResponse(dispatch, response);
    return dispatchSuccess(dispatch, success, data);
  } catch (e: unknown) {
    dispatchError(dispatch, fail, isSubmission, e.toString());
  }
}

async function handleResponse<T extends ApiResponseWrapper<T>>(
  dispatch: Dispatch,
  response: Response
) {
  const data = (await response.json()) as T;

  if (response.ok) {
    return data;
  }

  /**
   * !IMPORTANT: According to our conversation with Mahendra and Ehsan it was agreed
   * to go with the same approach we use on mobile apps with the only exception for
   * the specified endpoint.
   * @todo: should be revised once BE completely switched to the REAL v700 API and verified that we have 401 in every needed place.
   */
  if (data.authError && !response.url.includes('/api/portal/user')) {
    await logoutUser()(dispatch);

    localStorage.removeItem('accessToken');
    document.location.assign('/login');

    throw new Error(data.authError);
  }

  const error =
    (data && data.invalidEmail) || //comes from `forgotpassword` endpoint in case of error
    (data && data.message) ||
    (data && data.error) ||
    (data &&
      data.errors &&
      data.errors.map((value: { type: string; message: string }) => value.message).join()) || //The
    // case when we can potentially have multiple errors returned by BE - another
    // BE response-wrapper?
    response.statusText;
  throw new Error(error || DEFAULT_ERROR);
}

function dispatchSuccess<R>(dispatch: Dispatch, success: string, result: R) {
  dispatch({ type: success, payload: result });
  return result;
}

function dispatchError(dispatch: Dispatch, fail: string, isSubmission: boolean, error: string) {
  dispatch({ type: fail, payload: error });

  //Special case for handling form submissions - in case of error it should return
  // SubmissionError
  if (isSubmission) {
    throw new SubmissionError({ _error: error });
  }

  throw new Error(error);
}
