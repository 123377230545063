import { type Dispatch as ReactDispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type Dispatch as ReduxDispatch } from 'redux';
import { useAudioRecorder } from 'react-audio-voice-recorder';

import { useAudioLevels } from 'Shared/hooks/useAudioLevels';
import { setFlashMessage } from 'Shared/actions/service';
import { FlashMessageType, TaskView } from 'Shared/types/shared';

interface useProductionProps {
  task: TaskView;
  setIsAnswered: ReactDispatch<SetStateAction<boolean>>;
}

/**
 * Important Note for local development:
 * @see https://stackoverflow.com/questions/40144036/javascript-getusermedia-using-chrome-with-localhost-without-https
 */
export function useProduction({ task, setIsAnswered }: useProductionProps) {
  const dispatch: ReduxDispatch = useDispatch();
  const [microphoneAccessFailedError, setMicrophoneAccessFailedError] =
    useState<DOMException>(null);
  const { startRecording, stopRecording, isRecording, recordingBlob } = useAudioRecorder(
    {},
    setMicrophoneAccessFailedError
  );
  const { startAnalysis, stopAnalysis, audioLevel } = useAudioLevels();

  const handleReplay = async () => {
    const audio = new Audio(URL.createObjectURL(recordingBlob));
    await audio.play();
  };

  const handleMarkCorrect = (e: SyntheticEvent & { target: HTMLButtonElement }) => {
    e.preventDefault();

    setFlashMessage({
      message: 'Nice Job, your answer is correct!',
      type: FlashMessageType.SUCCESS,
    })(dispatch);

    setIsAnswered(true);
  };

  return {
    handleStart: () => {
      startRecording();
      void startAnalysis();
    },
    handleStop: () => {
      stopRecording();
      setIsAnswered(true);
      stopAnalysis();
    },
    isRecording,
    microphoneAccessFailedError,
    audioLevel,
    handleReplay,
    handleRetry: () => {
      setIsAnswered(false);
    },
    handleMarkCorrect,
  };
}
