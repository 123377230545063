import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStore } from 'react-redux';

import { User, USER_TYPES } from 'Shared/types/shared';
import { getActivity, startScheduledSession } from 'Shared/actions/therapy';
import { TherapyActivity } from 'Shared/types/api';

export interface WelcomeProps {
  authUser: User;
  setScheduleId: Dispatch<SetStateAction<number>>;
}

export function useWelcome({ authUser, setScheduleId }: WelcomeProps) {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [therapyActivity, setTherapyActivity] = useState<TherapyActivity>();

  const fetchTherapyActivity = async ({ patientId }: { patientId: number }) => {
    setIsLoading(true);
    try {
      const response = await getActivity({ patientId })(store.dispatch.bind(store));
      setTherapyActivity(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartSession = () => {
    setScheduleId(therapyActivity?.sessionData.scheduleId);
  };

  useEffect(() => {
    if (authUser.user.type === USER_TYPES.PATIENT) {
      void fetchTherapyActivity({ patientId: authUser.user.userId });
    }
  }, [authUser]);

  return { isLoading, therapyActivity, handleStartSession };
}
