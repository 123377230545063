import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { osName, osVersion, mobileModel, mobileVendor } from 'react-device-detect';

import { User } from 'Shared/types/shared';
import { completeTask, startScheduledSession } from 'Shared/actions/therapy';
import { type TherapySessionResponse } from 'Shared/types/api';
import { SkipReason, SkipType, TaskDoneRequestBody, TaskType } from 'Shared/types/task';
import { getAllTasksFlat } from 'Shared/actions/task';

export interface SessionProps {
  authUser: User;
  scheduleId: number;
}

export function useSession({ authUser, scheduleId }: SessionProps) {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [taskTypes, setTaskTypes] = useState<TaskType[]>();
  const [therapySession, setTherapySession] = useState<TherapySessionResponse>();
  const [activeTaskIndex, setActiveTaskIndex] = useState(0);

  const goToNextTask = () => {
    setActiveTaskIndex(activeTaskIndex + 1);
  };

  const skipTask = async ({ type, reason }: { type: SkipType; reason: SkipReason }) => {
    goToNextTask();

    await completeTask({
      patientId: authUser.user.userId,
      requestBody: [
        {
          uuid: uuidv4(),
          sessionId: therapySession.tasks[activeTaskIndex].sessionId,
          patientId: authUser.user.userId,
          userId: authUser.user.userId,
          taskType: therapySession.tasks[activeTaskIndex].taskType,
          taskId: therapySession.tasks[activeTaskIndex].taskId, //TODO: Maybe we have to skip this entirely
          accuracy: 0, //TODO: calculate accuracy
          latency: 1.3288979530334473, //TODO: calculate latency
          skipType: type,
          skipReason: reason,
          startTime: Math.floor(Date.now() / 1000), //TODO: calculate startTime
          endTime: Math.floor(Date.now() / 1000), //TODO: calculate startTime
          timestamp: Math.floor(Date.now() / 1000), //TODO: calculate timestamp
          clientVersionNumber: '7.0', //TODO
          systemVersionNumber: '', //TODO
          clientHardwareType: `${mobileVendor} ${mobileModel}`,
          clientOSVersion: osVersion,
          taskJson: JSON.stringify(therapySession.tasks[activeTaskIndex]), //TODO
          additionalDataJson: JSON.stringify({
            events: [
              { type: 'foreground', timestamp: 1721440599 },
              { type: 'pause_skip', timestamp: 1721440601 },
              { type: 'SINGLE_ITEM', timestamp: 1721440603, skipReason: 'TooEasy' },
              { type: 'taskCompleted', timestamp: 1721440603 },
            ],
          }), //TODO
          screenshotUrl: '', //TODO
          sendAttempts: 0, //TODO
        } as TaskDoneRequestBody,
      ],
    })(store.dispatch.bind(store));
  };

  const fetchStartScheduledSession = async ({
    patientId,
    scheduleId,
  }: {
    patientId: number;
    scheduleId: number;
  }) => {
    setIsLoading(true);
    try {
      const response = await startScheduledSession({
        patientId,
        scheduleId,
      })(store.dispatch.bind(store));
      setTherapySession(response);

      const taskTypes = await getAllTasksFlat()(store.dispatch.bind(store));
      setTaskTypes(taskTypes);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchStartScheduledSession({ patientId: authUser.user.userId, scheduleId });
  }, [authUser, scheduleId]);

  return {
    isLoading,
    therapySession,
    taskTypes,
    activeTaskIndex,
    goToNextTask,
    skipTask,
  };
}
