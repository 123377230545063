import React from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import moment from 'moment-timezone';

import { HTML_CONTENT_ID, MOBILE_APP_SOURCES } from 'Shared/constants';
import { App } from 'Shared/app';

import './src/themes/2023/index.scss';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'A few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

const queryParams: { source?: string } = queryString.parse(document.location.search);

if (!localStorage.getItem('anonymousId')) {
  localStorage.setItem('anonymousId', uuidv4());
}

// !!! IMPORTANT: Specific logic to visually hide header and footer if the WebApp is loaded within a MobileApp
// https://constanttherapy.atlassian.net/browse/WEBAPP-565
if (queryParams.source && MOBILE_APP_SOURCES.includes(queryParams.source.toLowerCase())) {
  document.body.classList.add('withinMobileApp');
}

const mountingPoint = document.getElementById(HTML_CONTENT_ID);

ReactDOM.render(<App />, mountingPoint);
