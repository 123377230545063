import React, { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import {
  faMicrophoneLines,
  faMicrophoneAltSlash,
  faCirclePlay,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { CTUI } from '@thelearningcorp/ct-web-packages';

import styles from './index.module.scss';

interface ControlsProps {
  isAnswered: boolean;
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  audioLevel: number;
  handleReplay: () => Promise<void>;
  handleRetry: () => void;
  handleMarkCorrect: (e: SyntheticEvent) => void;
}

export const Controls: FC<ControlsProps> = ({
  isAnswered,
  isRecording,
  startRecording,
  stopRecording,
  audioLevel,
  handleReplay,
  handleRetry,
  handleMarkCorrect,
}) => {
  const currentLevelWidth = isRecording ? Math.min(audioLevel * 3, 100) : 0;

  if (isAnswered) {
    return (
      <div className={classNames(styles.controls, styles.answered)}>
        <CTUI.Button
          text="Replay"
          icon={faCirclePlay}
          customClasses={styles.button}
          onClick={handleReplay}
        />
        <CTUI.Button
          text="Try again"
          icon={faRotateRight}
          customClasses={styles.button}
          onClick={handleRetry}
        />
        <CTUI.Button
          text="Mark correct"
          icon={faCircleCheck}
          customClasses={styles.button}
          onClick={handleMarkCorrect}
        />
      </div>
    );
  }

  return (
    <div className={styles.controls}>
      <div className={styles.audioLevel}>
        <span className={styles.optimalLevel} />
        <span className={styles.currentLevel} style={{ width: `${currentLevelWidth}%` }} />
      </div>
      {isRecording && (
        <CTUI.Button
          text="Stop"
          icon={faMicrophoneAltSlash}
          onClick={stopRecording}
          customClasses={styles.startStopButton}
        />
      )}
      {!isRecording && (
        <CTUI.Button
          text="Start"
          icon={faMicrophoneLines}
          onClick={startRecording}
          customClasses={styles.startStopButton}
        />
      )}
    </div>
  );
};
