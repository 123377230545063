import React, { FC } from 'react';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { faForward, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { SkipModal } from 'App/therapy/session/taskViewComponent/footer/skipModal';

import { SkipReason, SkipType } from 'Shared/types/task';

import { useFooter } from './useFooter';

import styles from './index.module.scss';

interface FooterProps {
  isAnswered: boolean;
  goToNextTask: () => void;
  skipTask: ({ type, reason }: { type: SkipType; reason: SkipReason }) => Promise<void>;
}

export const Footer: FC<FooterProps> = ({ isAnswered, goToNextTask, skipTask }) => {
  const { modalRef, isSkipTaskModalShown, showModal, hideModal } = useFooter();

  return (
    <footer className={styles.footer}>
      {isAnswered && (
        <CTUI.Button
          text="Next"
          icon={faArrowRightLong}
          onClick={goToNextTask}
          customClasses={styles.button}
        />
      )}
      <CTUI.Button
        text="Skip"
        icon={faForward}
        onClick={showModal}
        disabled={isAnswered}
        customClasses={styles.button}
      />
      {isSkipTaskModalShown && (
        <SkipModal modalRef={modalRef} hideModal={hideModal} skipTask={skipTask} />
      )}
    </footer>
  );
};
