import _ from 'lodash';
import { isIOS, isMacOs, isAndroid, mobileVendor, osName } from 'react-device-detect';
import publicIp from 'public-ip';
import moment from 'moment-timezone';
import queryString from 'query-string';

import config from 'Shared/config.json';
import { USER_TYPES } from 'Shared/constants';
import { User } from 'Shared/types/shared';

/**
 * The reason for having `username` and `email` as a separate params is that we can have them
 * passed via url-params
 */
export function getDisplayName({
  userObject,
  username,
  email,
}: {
  userObject?: User;
  username?: string;
  email?: string;
}) {
  let displayName = _.get(userObject, 'user.firstName');

  if (_.isEmpty(displayName)) {
    displayName = _.get(userObject, 'user.username');
  }

  if (_.isEmpty(displayName)) {
    displayName = _.get(userObject, 'user.email');
  }

  if (_.isEmpty(displayName)) {
    displayName = username;
  }

  if (_.isEmpty(displayName)) {
    displayName = email;
  }

  // Trims username from the beginning till `@` sign, so if that was an email we'll show only meaningful part of it
  if (displayName && displayName.indexOf('@') !== -1) {
    displayName = displayName.substring(0, displayName.indexOf('@'));
  }

  return displayName;
}

export function getDownloadDestination(authUser?: User) {
  let { itunesApp: idTunes, googleApp: idGoogle, amazonApp: idAmazon } = config;
  const formattedParams = queryString.stringify(
    {
      userType: authUser && authUser.user.type,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  if (authUser && authUser.user.type === USER_TYPES.CLINICIAN) {
    idTunes = config.clin_itunesApp;
    idGoogle = config.clin_googleApp;
    idAmazon = config.clin_amazonApp;
  }

  if (mobileVendor.toLowerCase() === 'amazon') {
    return `https://www.amazon.com/gp/product/${idAmazon}`;
  }

  if (isIOS || isMacOs) {
    return `https://itunes.apple.com/us/app/constant-therapy/id${idTunes}`;
  }

  if (isAndroid || osName === 'Chromium OS') {
    return `https://play.google.com/store/apps/details?id=${idGoogle}`;
  }

  return `/start?${formattedParams}`;
}

export function getDomainsKey(key: string) {
  return encodeURIComponent(key.toLowerCase().replaceAll(/\s+/gi, '-'));
}

export function getTherapiesCompositeKey(key: string, subKey: string) {
  return encodeURIComponent(`${key.toLowerCase().replaceAll(/\s+/gi, '-')}-${subKey}`);
}

export function getBaseUrl({
  apiSection = 'service',
  apiVersion,
  serverUrl = '/',
}: {
  apiSection?: string;
  apiVersion?: string;
  serverUrl?: string;
} = {}) {
  const configKey = process.env.ENV as keyof typeof config.environment;
  const apiSectionKey =
    apiSection as keyof (typeof config.environment)[typeof configKey]['apiSections'];
  return (
    serverUrl +
    config.environment[configKey].apiSections[apiSectionKey] +
    `-${apiVersion || config.environment[configKey].apiVersion}`
  );
}

export function convertDatetimeToUTC(dateObj: { day: number; month: number; year: number }) {
  return moment(new Date(dateObj.year, dateObj.month - 1, dateObj.day));
}

export function getUserIp() {
  return publicIp.v4().then(
    (response) => {
      return Promise.resolve(response);
    },
    () => {
      return Promise.resolve('1.1.1.1');
    }
  );
}

/**
 * TODO: This function is not in use, bu kept here for future uses
 * https://learn.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
 */
async function isWin11() {
  try {
    const ua = await navigator.userAgentData.getHighEntropyValues(['platformVersion']);
    const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
    if (majorPlatformVersion >= 13) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
  }
}
