import { useState } from 'react';

export function useTherapy() {
  const [scheduleId, setScheduleId] = useState<number>();

  return {
    scheduleId,
    setScheduleId,
  };
}
